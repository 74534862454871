import req from './axios';

export default {
  create(form) {
    console.log(`API: protect.create(`, form, `)`);
    return req.post(`/protect/`, form).then((response) => response.data);
  },

  list(model) {
    console.log(`API: protect.list(${model})`);
    let params = {
      model: model || null,
    };
    return req
      .get(`/protect/`, { params: params })
      .then((response) => response.data);
  },

  get(key) {
    console.log(`API: protect.get(${key})`);
    return req.get(`/protect/${key}`).then((response) => response.data);
  },

  overrideProtectPrefs(tagKey, form) {
    console.log(`API: protect.overrideProtectPrefs(${tagKey}, ${form})`);
    return req
      .post(`/protect/${tagKey}/override`, form)
      .then((response) => response.data);
  },

  preregActivate(preregKey, tagUrl) {
    console.log(`API: protect.preregActivate(${preregKey}, ${tagUrl})`);
    let form = {
      prereg: preregKey,
      tagUrl: tagUrl,
    };
    return req
      .post(`/protect/prereg/activate`, form)
      .then((response) => response.data);
  },

  search(email, org) {
    console.log(`API: protect.search(`, email, `)`);
    let params = { email: email, org: org };
    return req
      .get(`/protect/search`, { params: params })
      .then((response) => response.data);
  },

  tagAKidReplace(tagKey, form) {
    console.log(`API: protect.tagAKidReplace(${tagKey}, ${form})`);
    return req
      .post(`/protect/${tagKey}/replace`, form)
      .then((response) => response.data);
  },


  update(key, form) {
    console.log(`API: protect.update(`, form, `)`);
    return req.post(`/protect/${key}`, form).then((response) => response.data);
  },
};
