import req from './axios'

import utils from './utils.js'
import AuditApi from './audit.js'
import alertsApi from './alerts.js'
import itemsApi from './items.js'
import claimsApi from './claims.js'
import adminApi from './admin.js'
import lossReportsApi from './lossReports.js'
import ordersApi from './orders.js'
import orgApi from './org.js'
import messagesApi from './messages.js'
import shipmentsApi from './shipments.js'
import usersApi from './users.js'
import valetApi from './valet.js'
import protectApi from './protect.js'

const api = {
  connectionStatus: null,

  connectionCallbacks: [],

  updateConnectionStatus(newStatus /*, response, error*/) {
    if (this.connectionStatus != newStatus) {
      this.connectionStatus = newStatus
      this.connectionCallbacks.forEach((fn) => fn(newStatus))
    }
  },

  audit: AuditApi,

  alerts: alertsApi,

  lossReports: lossReportsApi,

  items: itemsApi,

  utils: utils,

  claims: claimsApi,

  admin: adminApi,

  orders: ordersApi,

  org: orgApi,

  protect: protectApi,

  shipments: shipmentsApi,

  valet: valetApi,

  messages: messagesApi,

  users: usersApi,

  ping() {
    return req.get('/utils/ping').then((response) => response.data)
  },

  fetchClaims(orgKey, workCategory) {
    console.debug(`fetchClaims(${orgKey}, ${workCategory})`)
    return req
      .get(`org/${orgKey}/claims?work_category=${workCategory}`)
      .then((response) => response.data)
  },

  fetchPostcardableItems(orgKey) {
    console.debug(`fetchPostcardableItems(${orgKey})`)
    return req.get(`org/${orgKey}/postcards`).then((response) => response.data)
  },

  sendPostcards(form) {
    return req.post(`items/postcards`, form).then((response) => response.data)
  },

  searchItems(query) {
    /* query: {
          q: str,
          category: str,
          page: int,
          organization: key(str),
       }
     */
    console.debug(`searchItems(${query})`)
    return req.get('/items/', { params: query }).then((response) => {
      return response.data
    })
  },

  getItem(key) {
    return req.get(`/items/${key}`).then((response) => response.data)
  },

  getCurrentUser(orgKey, mode = 'user') {
    // mode = user, full
    let params = { mode }
    if (orgKey) {
      params.org = orgKey
    }
    return req
      .get(`/users/current`, { params: params })
      .then((response) => response.data)
  },

  getUserOrgs() {
    return req
      .get(`/users/current/organizations`)
      .then((response) => response.data)
  },

  getUserFullPlate() {
    console.debug(`getUserFullPlate`)

    return req
      .get(`/users/current/full-plate`)
      .then((response) => response.data)
  },

  getOnYourPlate(orgKey) {
    console.debug(`getOnYourPlate(${orgKey})`)

    return req.get(`/org/${orgKey}/plate`).then((response) => response.data)
  },

  getCategories(orgKey) {
    return req
      .get(`/org/${orgKey}/categories`)
      .then((response) => response.data)
  },

  searchConsumers(orgKey, q) {
    return req
      .get(`/org/${orgKey}/consumers?q=${q}`)
      .then((response) => response.data)
  },

  searchIMEIs(orgKey, q) {
    return req
      .get(`/org/${orgKey}/imei?q=${q}`)
      .then((response) => response.data)
  },
}

req.interceptors.response.use(
  (response) => {
    api.updateConnectionStatus('online', response)
    return response
  },
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code that
      // falls out of the range of 2xx
      if (
        error.response.status == 401 &&
        !location.pathname.startsWith('/login')
      ) {
        let origin = location.origin
        let route = location.hash
        let app = location.pathname.replace(/^\/+|\/+$/g, '')
        console.log('Redirecting to login', origin, route, app)
        if (route.startsWith('#/')) route = route.slice(2)

        let path = ''
        if (route.length > 0) {
          path += `/r/${route}`
        }
        if (app) {
          path += `/a/${app}`
        }
        if (path) {
          location.href = `${origin}/login/#${path}`
        } else {
          location.href = `${origin}/login`
        }
      }
    } else if (error.request) {
      // The request was made but no response was received `error.request` is
      // an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      api.updateConnectionStatus('offline', null, error)
    } else {
      // Something happened in setting up the request that triggered an Error
    }
    console.log(error.config)

    return Promise.reject(error)
  }
)

export default api
